import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/agree/:lang?",
    name: "agree",
    component: () => import('../views/Agree.vue')
  },
  {
    path: "/privacy/:lang?",
    name: "privacy",
    component: () => import('../views/Privacy.vue')
  },
  {
    path:'/acountnotice/:lang?',
    name:'acountnotice',
    component:()=>import('../views/AcountNotice.vue')
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
