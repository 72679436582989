<template>
  <div class="home">
    <div class="pc hidden-sm">
      <div class="one flex">
        <div class="left">
          <div class="logo"><img src="../assets/logo.png" alt="" /></div>
          <div class="right">
            <div class="title">
              <p>手机云管家</p>
            </div>
            <div class="desc">
              <p class="deac_first">
                手机空间过满，相册内几百张相似的图片让你烦不胜烦了吗？
              </p>
              <p class="deac_second">
                手机云管家，拥有超大空间云相册的手机管家，智能扫描上传 云空间，轻松管理您的手机空间，帮你快速清理手机照片、清理 手机视频和重复联系人的应用，它可以为你的手机移除所有不需 要的文件和占用内存过大的视频。
              </p>
            </div>
            <div class="btn">
              <img src="../assets/1.png">
            </div>
          </div>
        </div>
        <div class="flex1">
          <div class="banner"><img src="../assets/phone_show.png" alt="" /></div>
        </div>
      </div>
      <div class="two">
        <div class="container">
          <div class="info_header">
            <h2>功能介绍</h2>
          </div>
          <div class="info_center">
            <ul>
              <li>
                <img src="../assets/phone01.png">
              </li>
              <li >
                <img src="../assets/phone02.png">
              </li>
              <li>
                <img src="../assets/phone03.png">
              </li>
              <li>
                <img src="../assets/phone04.png">
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer">
        <p>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="row_click1">陕ICP备17001630号</a>© <span class="years">{{yeardata}}西安乐推网络科技有限公司 All Rights Reserved.</span>
        </p>
      </div>
    </div>

    <div class="mb hidden-md">
      <div class="one">
        <div class="container">
          <div class="logo"><img src="../assets/logo.png" alt="" /></div>
          <div class="banner"><img src="../assets/phone.png" alt="" /></div>
          <div class="title">
            <p>手机云管家</p>
          </div>
          <div class="desc">
            <p class="desc_first">
              手机空间过满，相册内几百张相似的图片让你烦不胜烦了吗？
            </p>
            <p class="desc_second">
              手机云管家，拥有超大空间云相册的手机管家，智能扫描上传
              云空间，轻松管理您的手机空间，帮你快速清理手机照片、清理
              手机视频和重复联系人的应用，它可以为你的手机移除所有不需
              要的文件和占用内存过大的视频。
            </p>
          </div>
          <div class="btn">
            <div>
              <img src="../assets/1.png">
            </div>
          </div>
        </div>
      </div>
      <div class="two">
         <div class="title">
           <p>产品展示</p>
         </div>
         <div class="content">
           <div class="img_fornt">
             <img class="one_img" src="../assets/phone01.png">
             <img src="../assets/phone02.png">
           </div>
           <div class="img_end">
             <img class="one_img" src="../assets/phone03.png">
             <img src="../assets/phone04.png">
           </div>
         </div>
      </div>
      <div class="footer">
          <div>
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="row_click1">陕ICP备17001630号</a>© <span class="years">{{yeardata}}西安乐推网络科技有限公司</span>
            <p> All Rights Reserved.</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  components: {},
  data(){
    return{
      yeardata:'',
    }
  },
  mounted() {
    // 实时获取当前年份
    let data = new Date();
    console.log(data)
    this.yeardata = data.getFullYear();
  }
};
</script>
<style scoped lang="less">
.home {
  .pc {
    .one {
      min-height: 1000px;
      background: linear-gradient(180deg, #3D7EFF 0%, #38D5F9 100%);

      .left {
        padding-top: 4.68vw;
        padding-left: 18.75vw;
        //padding-right: 4.5vw;
        font-size: 0;
        .logo {
          margin-bottom: 8.43vw;
          text-align: left;
        }
      }

      .right {
        width: 100%;
        //height: 52.5vw;
        .title {
          p {
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            text-align: left;
            font-size: 43px;
            font-weight: bold;
            color: #FFFFFF;
          }
        }
        .desc {
          margin-top: 3.13vw;
          max-width: 40.5vw;
          .deac_first {
            font-size: 14px;
            font-family:SourceHanSansCN-Medium, SourceHanSansCN;
            color: #FFFFFF;
            font-weight: 500;
            line-height: 21px;
            text-align: left;
            margin-bottom: 0.73vw;
          }
          .deac_second{
            width: 20.4vw;
            height: 5vw;
            font-size: 14px;
            font-family:SourceHanSansCN-Medium, SourceHanSansCN;
            color: #FFFFFF;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            margin-bottom: 3.13vw;
          }

        }
        .btn {
          width: 6.25vw;
          height: 6.25vw;
          border-bottom-right-radius: 20px;
          border-top-left-radius: 20px;
          background-color: #FFFFFF;
          img{
            width: 5vw;
            height: 5vw;
            margin-top: 0.7vw;
          }
        }
      }
    }
    .two {
      /* 功能介绍 */
      .container{
        overflow:hidden;
        .info_header{
          margin-top: 5.20vw;
          text-align: center;
          h2{
            margin-bottom:3.65vw;
            font-size: 43px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            line-height: 3.39vw;
          }
        }
        .info_center{
          overflow:hidden;
          margin-bottom: 2.60vw;
          ul{
            list-style: none;
            li{
              border-radius: 30px;
              display: inline-block;
              text-align: center;
              vertical-align: top;
              .img{
                margin: 6.48vh 0 3.5vh 0;
                width: 4.63vw;
              }
            }
          }
        }
      }


    }
    .footer {
      margin-bottom: 4.1vw;
      p {
        font-size: 14px;
        font-family:SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #909399;
        line-height: 21px;
      }
    }
  }

  /*750 尺寸 以上 的屏幕样式*/
  @media (min-width: 769px) {
    .hidden-md {
      display: none;
    }
  }

  /*750 尺寸 以下 的屏幕样式*/
  @media (max-width: 768px) {
    .hidden-sm {
      display: none;
    }
    .mb {
      .one {
        width: 100%;
        //height: 177.9vw;
        background: linear-gradient(180deg, #3D7EFF 0%, #38D5F9 100%);
        .container {
          padding: 0 0.8vw;
          .logo {
            padding-top: 9.47vw;
            img {
              max-width: 27.73vw;
            }
          }
          .banner {
            padding-top: 5.5vw;
            img {
              max-width: 98.4vw;
            }
          }
          .title {
            font-size: 6vw;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 8.7vw;
            text-align: left;
            margin-left: 6.1vw;
            p {
              margin-bottom: 7vw;
            }
          }
          .desc {
            margin-left: 6.1vw;
            padding-top: 1.3vw;
            font-size: 3vw;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            text-align: left;
            color: #FFFFFF;
            .desc_first{
              line-height: 4.4vw;
              margin-bottom: 1.87vw;
            }
            .desc_second{
              width: 308px;
              height: 73px;
              line-height: 5.5vw;
            }
          }
          .btn {
            padding: 4.7vw 0;
            div{
              margin: 0 auto;
              width: 100px;
              border-top-left-radius: 10px;
              border-bottom-right-radius: 10px;
              background-color: #FFFFFF;
              img{
                margin-top: 2.5vw;
                margin-bottom: 1vw;
                width: 80px;
              }
            }
          }
        }
      }
      .two {
        .title{
           margin-top: 13.3vw;
           margin-left: 8.9vw;
           text-align: left;
           margin-bottom: 9.3vw;
           p{
             font-size: 5.73vw;
             font-weight: bold;
             font-family: SourceHanSansCN-Bold, SourceHanSansCN;
             color: #303133;
             line-height: 8.6vw;
           }
        }
        .content{
          //padding-left: 8vw;
          //padding-right: 8vw;
          .img_fornt{
            img{
              width:155px;
            }
          }
          .img_end{
            img{
              //margin: 7vw;
              width:155px;
            }
          }
        }

      }
      .footer {
        margin-top: 5.3vw;
        div {
          margin-bottom: 9.4vw;
          font-size: 2.93px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #909399;
          line-height: 4vw;
        }
      }
    }
  }
}
</style>
